<template>
	<div class="main-refund">
        <div class="top-header shop-cart-main1">
            <div class="search" style="justify-content: flex-start;">
                <a-input placeholder="客户姓名/手机号/卡号" style="width: 350px;"></a-input>
                <a-button type="primary">查询</a-button>
            </div>
            <div  class="searcHeader-refund" style="display: none;">
                <div class="memList" >
                    <div  class="mem-item" v-for="(item,index) in 10" >
                        <div  class="memInfo">
                            <div  class="memName">安罗3</div>
                            <div  class="gradName">黄金会员VIP</div>
                            <div  class="memCard">34123542334</div>
                        </div>
                        <div  class="memPhone">34123542334</div>
                        <div  class="memShop">伊丽汇美容SPA</div>
                    </div>
                </div>
            </div>
            <div style="padding: 5px; margin-top: 10px;">
                <div  class="mem_details">
                    <div   class="details_top">
                        <img  src="../../../src/assets/image_new/default_user.png" alt="" class="mem_img">
                        <div  class="de_right">
                            <div>
                                <span  class="name">冯媛</span>
                                <span  class="grad_name">荣耀王者</span>
                            </div>
                            <div   class="details_info">
                                <span>手机: 187****2887</span>
                                <span>导购：老板试用（勿动）</span>
                                <span>最近消费时间： 
                                    <span  > 2023-09-14 15:25:38.0（距今0天） </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div   class="details_bot">
                        <div   class="item">
                            <p   class="number">1500.00</p>
                            <p   class="text">可用储值</p>
                        </div>
                        <div   class="line"></div>
                        <div   class="item">
                            <p   class="number">0.00</p>
                            <p   class="text">储值卡余额</p>
                        </div>
                        <div   class="line"></div>
                        <div   class="item">
                            <p   class="number">2704.00</p>
                            <p   class="text">套卡剩余金额</p>
                        </div>
                        <div   class="line"></div>
                        <div   class="item">
                            <p   class="number">129.49</p>
                            <p   class="text">可用积分</p>
                        </div>
                        <div   class="line"></div>
                        <div   class="item">
                            <p   class="number">0.00</p>
                            <p   class="text">总欠款</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order_box">
            <div class="stitle">
                <a class="close" :class="type == 1?'open':''" href="javascript:;" @click="changeType(1)">消费退款</a> 
                <a class="close" :class="type == 2?'open':''" href="javascript:;" @click="changeType(2)">退套卡</a>
            </div>
            <div class="ivu-alert ivu-alert-info mt20">
                <span class="ivu-alert-message" v-if="type == 1">温馨提示：退费时可不输入会员信息，直接根据日期订单号查询对应的订单进行退费；消费退款仅支持单次项目及产品；扣套卡不支持退款，可整笔订单作废</span>
                <span class="ivu-alert-message" v-if="type == 2">温馨提示：仅显示当前会员有效的套卡，每次仅能退一个套卡；每个套卡仅能退款一次，退款后该套卡自动变为“无效，已退款”</span>
                <span class="ivu-alert-desc"></span> 
            </div>
        </div>
        <div class="demo-split" v-show="type == 1">
            <div class="demo-split-pane left">
                <div class="ivu-card-bordered ivu-card left-sel">
                    <span>日期</span>
                    <a-range-picker style="width: 220px;"></a-range-picker>
                    <a-input placeholder="订单号" style="width: 140px;"></a-input>
                    <a-button type="primary">查询</a-button>
                </div>
                <div class="table">
                    <a-table :columns="columns" :data-source="datas" 
                    :pagination="pagination" @change="handleTableChange" :loading="loading"
                    :scroll="{y: 500}"
                    >
                    
                        
                    </a-table>
                </div>
            </div>
            <div class="demo-split-pane right">
                <div class="ivu-card-bordered ivu-card left-sel">
                    <span>退款时间</span>
                    <a-date-picker style="width: 220px;" :showTime="true"></a-date-picker>
                    <span class="ml20">备注</span>
                    <a-input class="ml20" placeholder="备注最多输入250个字符" type="textarea" style="width: 500px;"></a-input>
                    <a-button type="primary" class="ml10">结算</a-button>
                </div>
                <div class="table">
                    <a-table :columns="columns1" :data-source="datas1" 
                    :pagination="false" @change="handleTableChange" :loading="loading"
                    :scroll="{y: 500}"
                    >
                        <div slot="pay3" slot-scope="pay3,record">
                            <a-input-number min="0" style="width: 80px;" :value="record.pay3"></a-input-number>
                        </div>
                        
                    </a-table>
                    <div  style="padding-top: 10px;">
                        <span >退款总数<span  class="total_num">1</span>
                        <span class="total_money">退还储值<span  class="total_num">0.00</span></span>
                        <span class="total_money">退费总额<span  class=" total_num">25.00</span></span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="demo-split" v-show="type == 2">
            <div class="demo-split-pane left">
                <div class="ivu-card-bordered ivu-card left-sel">
                    <a-input placeholder="卡项/套餐名称" style="width: 140px;"></a-input>
                    <a-button type="primary">查询</a-button>
                </div>
                <div class="table">
                    <a-table :columns="column3" :data-source="data3" 
                    :pagination="pagination" @change="handleTableChange" :loading="loading"
                    :scroll="{y: 500}"
                    >
                    
                        
                    </a-table>
                </div>
            </div>
            <div class="demo-split-pane right">
                <div class="ivu-card-bordered ivu-card left-sel">
                    <span>退款时间</span>
                    <a-date-picker style="width: 220px;" :showTime="true"></a-date-picker>
                    <span class="ml20">备注</span>
                    <a-input class="ml20" placeholder="备注最多输入250个字符" type="textarea" style="width: 500px;"></a-input>
                    <a-button type="primary" class="ml10">结算</a-button>
                </div>
                <div class="table">
                    <a-table :columns="columns4" :data-source="datas4" 
                    :pagination="false" @change="handleTableChange" :loading="loading"
                    :scroll="{y: 500}"
                    >
                        <div slot="pay3" slot-scope="pay3,record">
                            <a-input-number min="0" style="width: 80px;" :value="record.pay3"></a-input-number>
                        </div>
                        
                    </a-table>
                    <div  style="padding-top: 10px;">
                        <span >退款总数<span  class="total_num">1</span>
                        <span class="total_money">退还储值<span  class="total_num">0.00</span></span>
                        <span class="total_money">退费总额<span  class=" total_num">25.00</span></span></span>
                    </div>
                </div>
            </div>
        </div>
		
	</div>
</template>

<script>
	
	export default{
		components:{
			
		},
		data(){
			return {
				type:1,
                loading:false,
                pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
                columns: [{
						title: '订单号',
						dataIndex: 'order_sn',
						align: 'center',
						width: 200,
						scopedSlots: {
							customRender: 'skus'
						}
					},
					{
						title: '订单日期',
						dataIndex: 'time',
						align: 'center',
                        width: 160,
						// ellipsis: true
					},
					{
						title: '客户姓名',
						dataIndex: 'name',
						align: 'center',
						width: 100,
						scopedSlots: {
							customRender: 'name'
						}
					},
					{
						title: '实付金额',
						dataIndex: 'pay',
						align: 'center',
                        width: 80,
						// ellipsis: true
					}
				],
				datas: [
                    {order_sn:'202258596878788745588',time:'2022-08-15 16:23:23',name:'王志锋',pay:100}
                ],
                datas1: [
                    {name:'商品名称',sku:'红色',name:'王志锋',type:'项目',pay1:100,pay2:10,pay3:3,pay4:4,pay5:5}
                ],
                columns1: [{
						title: '商品名称',
						dataIndex: 'name',
						align: 'center',
						width: 150,
						scopedSlots: {
							customRender: 'skus'
						}
					},
					{
						title: '规格',
						dataIndex: 'sku',
						align: 'center',
                        width: 160,
						// ellipsis: true
					},
					{
						title: '类型',
						dataIndex: 'type',
						align: 'center',
						width: 100,
						scopedSlots: {
							customRender: 'name'
						}
					},
					{
						title: '项目总额',
						dataIndex: 'pay1',
						align: 'center',
                        width: 90,
					},
                    {
						title: '可退数量',
						dataIndex: 'pay2',
						align: 'center',
                        width: 90,
					},
                    {
						title: '退货数量',
						dataIndex: 'pay3',
						align: 'center',
                        width: 90,
                        scopedSlots: {
							customRender: 'pay3'
						}
					},
                    {
						title: '抵扣欠款',
						dataIndex: 'pay4',
						align: 'center',
                        width: 90,
					},
                    {
						title: '实退金额',
						dataIndex: 'pay5',
						align: 'center',
                        width: 90,
					}
				],
                column3: [{
						title: '套餐/卡项名称',
						dataIndex: 'order_sn',
						align: 'center',
						width: 150,
						scopedSlots: {
							customRender: 'skus'
						}
					},
					{
						title: '卡/套餐类型',
						dataIndex: 'type',
						align: 'center',
                        width: 120,
						// ellipsis: true
					},
					{
						title: '余次',
						dataIndex: 'name',
						align: 'center',
						width: 80,
						scopedSlots: {
							customRender: 'name'
						}
					},
                    {
						title: '截止时间',
						dataIndex: 'time',
						align: 'center',
                        width: 160,
						// ellipsis: true
					},
					{
						title: '实付金额',
						dataIndex: 'pay',
						align: 'center',
                        width: 100,
						// ellipsis: true
					}
				],
				data3: [
                    {order_sn:'套餐名称',time:'2022-08-15 16:23:23',name:'100',pay:100,type:'套餐'},
                    {order_sn:'套餐名称',time:'2022-08-15 16:23:23',name:'100',pay:100,type:'卡项'}
                ],
                datas4: [
                    {name:'商品名称',sku:'100',name:'王志锋',type:'100',pay1:100,pay2:10,pay3:3,pay4:4,pay5:5},
                    {name:'商品名称2',sku:'100',name:'王志锋',type:'100',pay1:100,pay2:10,pay3:3,pay4:4,pay5:5},
                    {name:'商品名称3',sku:'100',name:'王志锋',type:'100',pay1:100,pay2:10,pay3:3,pay4:4,pay5:5},
                    {name:'商品名称4',sku:'100',name:'王志锋',type:'100',pay1:100,pay2:10,pay3:3,pay4:4,pay5:5},
                    {name:'商品名称6',sku:'100',name:'王志锋',type:'100',pay1:100,pay2:10,pay3:3,pay4:4,pay5:5},
                ],
                columns4: [{
						title: '套餐/卡项名称',
						dataIndex: 'name',
						align: 'center',
						width: 150,
						scopedSlots: {
							customRender: 'skus'
						}
					},
					{
						title: '总次数',
						dataIndex: 'sku',
						align: 'center',
                        width: 160,
						// ellipsis: true
					},
					{
						title: '已用/出库',
						dataIndex: 'type',
						align: 'center',
						width: 100,
						scopedSlots: {
							customRender: 'name'
						}
					},
					{
						title: '购买总价',
						dataIndex: 'pay1',
						align: 'center',
                        width: 90,
					},
                    {
						title: '剩余金额',
						dataIndex: 'pay2',
						align: 'center',
                        width: 90,
					},
                    {
						title: '退款金额',
						dataIndex: 'pay3',
						align: 'center',
                        width: 90,
                        scopedSlots: {
							customRender: 'pay3'
						}
					},
                    {
						title: '抵扣欠款',
						dataIndex: 'pay4',
						align: 'center',
                        width: 90,
					},
                    {
						title: '实退金额',
						dataIndex: 'pay5',
						align: 'center',
                        width: 90,
					}
				],
			}
		},
		created(){
			var datas = this.datas;
            datas = datas.concat(datas);
            datas = datas.concat(datas);
            datas = datas.concat(datas);
            datas = datas.concat(datas);
            datas = datas.concat(datas);
            datas = datas.concat(datas);
            this.datas = datas;
            console.log('this.datas ',this.datas )
            var datas1 = this.datas1;
            datas1 = datas1.concat(datas1);
            datas1 = datas1.concat(datas1);
            datas1 = datas1.concat(datas1);
            datas1 = datas1.concat(datas1);
            datas1 = datas1.concat(datas1);
            datas1 = datas1.concat(datas1);
            this.datas1 = datas1;
		},
		computed:{
			
		},
		methods:{
            changeType(type){
                this.type = type;
            },
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				//this.getLists();
			},
		}
	}
</script>

<style>
.total_num {
    color: #ed4014;
    margin-left: 15px;
    font-weight: 800;
}
.total_money{
    margin-left: 130px;
    font-weight: 800;
}
.demo-split{
    width: 100%;
    display: flex;
}
.demo-split-pane {
    padding: 1px;
    flex: 1;
    width: 50%;
}
.demo-split-pane.left {
    width: 40%;
    flex: none;
}
.left-sel{
    line-height: 50px;
    padding: 12px;
}
.ivu-card-bordered {
    border: 1px solid #dcdee2;
    border-color: #e8eaec;
}
.ivu-card {
    display: block;
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    transition: all .2s ease-in-out;
}

.order_box .stitle {
    clear: right;
    margin-left: -1px;
    height: 35px;
    border-bottom: 1px solid #ddd;
    padding-top: 0;
}
.order_box .stitle .close {
    min-width:82px;
    width:88px;
    height: 25px;
    background: #ffffff !important;
    color: #505050;
    text-align: center;
    float: left; font-size:13px;
    margin-right: -1px;
    padding-top:8px;
    box-sizing: content-box;
    line-height: 20px;
    font-weight: normal;
    opacity: 1;
}
.order_box .stitle .open {
    min-width:82px;width:88px;
    height: 26px;
    transform: translateY(1px);
    border-bottom: 3px solid #ec9cb9;
    background: #ffffff;
    color: #505050; font-size:13px;
    text-align: center;
    float: left;
    margin-top: -2px;
    margin-right: -1px;
    padding-top: 8px;
    overflow: hidden;
    box-sizing: content-box;
    line-height: 20px;
    font-weight: normal;
    opacity: 1;
}
.order_box .stitle .open {
    border-bottom: 2px solid #548bde;
    background: #ffffff;
    color: #548bde;
}	
.order_box .ivu-alert-info {
    border: 1px solid #abdcff;
    background-color: #f0faff;
}
.order_box  .ivu-alert {
    position: relative;
    padding: 8px 48px 8px 16px;
    border-radius: 4px;
    color: #17233d;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}

.main-refund{
    padding: 20px;
    background: #ffffff;
}
.mem_details{
    padding: 20px 0;
    background: #fff;
    box-shadow: 0 0 5px 0 rgb(81 90 110 / 12%);
    border-radius: 4px;
}
.mem_details .details_top{
    padding: 0 50px;
    display: flex;
    align-items: center;
}
.mem_details .mem_img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 20px;
}
.mem_details .de_right .name {
    font-size: 16px;
    font-weight: 800;
}
.mem_details .de_right .grad_name {
    display: inline-block;
    line-height: 1;
    font-size: 14px;
    padding: 2px 3px;
    border-radius: 2px;
    background: linear-gradient(
90deg,#9f7242,#f6ca9d);
    margin-left: 5px;
}
.mem_details .de_right .details_info{
    color: #808695;
    font-size: 14px;
    margin-top: 5px;
}
.mem_details .de_right .details_info>span {
    display: inline-block;
    margin-right: 30px;
}
.mem_details .details_bot {
    display: flex;
    padding: 15px 0 0;
    align-items: center;
    width: 100%;
}
.mem_details .details_bot .item {
    width: 33.3%;
    flex: 1;
    text-align: center;
}
.mem_details .details_bot .item .number{
    color: #2b85e4;
    font-size: 20px;
    line-height: 1;
}
.mem_details .details_bot .item .text {
    color: #808695;
    font-size: 14px;
    line-height: 1;
    margin-top: 8px;
}
.mem_details .line {
    height: 32px;
    width: 1px;
    background: #e8eaec;
    flex: none;
}

.noSearch, .searcHeader-refund {
    position: relative;
    display: flex;
    align-items: center;
}
.searcHeader-refund{
    margin: 10px 0;
    width: 400px;
}
.shop-cart-main1 .searcHeader-refund .memList {
		position: absolute;
		top: 0px;
		left: 0;
		z-index: 2;
		background: #fff;
		width: 100%;
		border-radius: 4px;
		height: 300px;
		border: 1px solid #e5e5e5;
		overflow-y: auto;
	}
	.shop-cart-main1 .searcHeader-refund .memList .mem-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		cursor: pointer;
	}
	.shop-cart-main1 .searcHeader-refund .memList .mem-item .memInfo .memName {
    width: 100px;
}
.shop-cart-main .searcHeader-refund .memList .mem-item .memInfo .gradName, .shop-cart-main1 .searcHeader-refund .memList .mem-item .memInfo .memCard {
    color: #999;
}
	.shop-cart-main1 .searcHeader-refund .memList .mem-item .memShop{
		width: 100px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>
